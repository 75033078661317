import React from "react"
import Layout from "../../components/layout/Layout"
import Markdown from "../../components/markdown/Markdown"
import Section from "../../components/section/Section"
import css from "./We.module.css"
import IconVenus from "../../images/venus.svg"
import MainTitle from "../../components/titles/MainTitle"
import { Link } from "gatsby"
import MR from "../../images/mr.jpg"
import wefightIMG from "../../images/wefight.jpg"
import weworkIMG from "../../images/wework.jpg"

export default ({ pageContext: { url, data } }) => {
  return (
    <Layout
      type="HMF"
      className={css.we}
      slug={url}
      title="Nosotras"
      theme="we"
    >
      <MainTitle
        title="Nosotras"
        description="Movimiento Manuela Ramos, desde 1978 caminando por la igualdad"
        icon={<IconVenus />}
        theme="we"
      />
      <Section className={css.presentation}>
        <Markdown>{data.we.descripcion.childMarkdownRemark.html}</Markdown>
      </Section>
      <Section className={css.whoarewe}>
        <img src={MR} alt="primer logo" />
        <div>
          <h2>¿Quién es Manuela Ramos?</h2>
          <Markdown>{data.we.quienesSomos.childMarkdownRemark.html}</Markdown>
        </div>
      </Section>
      <Section className={css.visionmision}>
        <div className={css.vision}>
          <h2>Visión</h2>
          <Markdown>{data.we.vision.childMarkdownRemark.html}</Markdown>
        </div>
        <div className={css.mision}>
          <h2>Misión</h2>
          <Markdown>{data.we.mision.childMarkdownRemark.html}</Markdown>
        </div>
      </Section>
      <Section className={css.wefight}>
        <img src={wefightIMG} alt="#Luchamos" />
        <div>
          <p>
            <strong>#LUCHAMOS</strong> para fortalecernos, ganar autonomía e
            iniciar la transformación de nuestras vidas.
          </p>
          <Markdown>{data.we.luchamos.childMarkdownRemark.html}</Markdown>
        </div>
      </Section>
      <Section className={css.wework}>
        <div>
          <p>
            <strong>#TRABAJAMOS</strong> para lograr cambios
          </p>
          <Markdown>{data.we.trabajamos.childMarkdownRemark.html}</Markdown>
        </div>
        <img src={weworkIMG} alt="#Trabajamos" />
      </Section>

      <Section className={css.memories}>
        {data.memories && (
          <div className={css.memoriesContent}>
            <h2>Memorias Institucionales</h2>
            <div className={css.docsGrid}>
              {data.memories.map((memorie, i) => (
                <a
                  href={memorie.archivo.file.url}
                  target="_blank"
                  rel="noreferrer"
                  key={i}
                  className={css.doc}
                >
                  <span className="material-icons">subject</span>
                  <p>{memorie.nombre}</p>
                </a>
              ))}
            </div>
          </div>
        )}
      </Section>

      <Section className={css.findUs}>
        <h1>Encuéntranos</h1>
        <div className={css.findUsContent}>
          <div className={css.locations}>
            {data.locations.map((location, i) => (
              <div key={i} className={css.location}>
                <h2>{location.region}</h2>
                <Markdown>
                  {location.contacto.childMarkdownRemark.html}
                </Markdown>
              </div>
            ))}
          </div>
        </div>
      </Section>

      <Section className={css.programs}>
        <h1>Programas</h1>
        <div className={css.programsContent}>
          {data.programs.map((program, i) => (
            <Link key={i} to={`/${program.slug}`} className={css.program}>
              <img src={program.portada.file.url} alt="img" />
              <h3>{program.titulo}</h3>
            </Link>
          ))}
        </div>
      </Section>
    </Layout>
  )
}
